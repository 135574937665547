html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: rgb(245, 245, 245);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#root {
  height: 100%;
}

.MuiCollapse-wrapperInner {
  display: inline-flex !important;
  min-width: 100% !important;
}

.MuiAppBar-positionFixed {
  position: static !important;
  padding: 0 !important;
}

.contentContainer {
  width: 100%;
  height: 100vh;
}

.MuiSelect-selectMenu {
  white-space: normal !important;
}

.MuiCollapse-root.MuiCollapse-horizontal.MuiCollapse-entered {
  width: 100% !important;
  flex: 1 !important;
}

.MuiInputBase-root {
  width: '100%' !important;
}

.meter span {
  border-radius: 10px;

  display: block;
  transform: rotate(180deg);
}

.progress {
  background-color: #0093c5;
  animation: progressBar 1s ease-in-out;
  animation-fill-mode: both;
}

@keyframes progressBar {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
